@media(max-width:749px) {

    nav {
        position: fixed;
        width: calc(100% - 36px);
        z-index: 10;
    }

    .nav1 {
        display: flex;
        justify-content: space-between;
        padding-top: 36px;
        margin-left: -5px;
        background-color: #171717;
    }

    .topnav__burger {
        z-index: 1000;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
    }

    .logo {
        display: none;
    }

    .logotel {
        font-weight: 600;
        padding: 5px;
        padding-right: 15px;
        font-size: 16px;
        font-style: italic;
        letter-spacing: -0.24em;
        color: #ffa800;
    }

    a {
        text-decoration: none;
        color: white;

    }


    .topnav__liste {
        margin: 0;
        padding: 0;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        transition: all .6s cubic-bezier(1, .19, .02, .99);
        transform: translateY(0);
        display: inline-block;
        list-style: none;
        background-color: #171717;

    }

    .topnav__el {
        font-family: "Plus Jakarta Sans", sans-serif;
        list-style: none;
        font-size: 18px;
        line-height: 29px;
        font-weight: 100;
        font-style: normal;
        padding: 60px 0px;
        text-align: center;
        margin-right: 15%;
        transform: translateY(0%);
        opacity: 0;
        transition: all .6s cubic-bezier(1, .19, .02, .99);
    }

    .topnav--open .topnav__liste {
        transform: translateX(-90%);
    }

    .topnav--open .topnav__el {
        transform: translateY(0);
        opacity: 1;
    }

    .topnav--open .topnav__el:nth-child(1) {
        padding-top: 150px;
        transition-delay: 0.5s;
    }

    .topnav--open .topnav__el:nth-child(2) {
        transition-delay: 0.7s;
    }

    .topnav--open .topnav__el:nth-child(3) {
        transition-delay: 0.9s;
    }
}

@media (min-width:750px) and (max-width:1279px) {

    nav {
        position: fixed;
        width: calc(100% - 36px);
        z-index: 10;
    }

    .nav1 {
        display: flex;
        justify-content: space-between;
        padding-top: 36px;
        margin-left: 35px;
        background-color: #171717;
    }

    .topnav__burger {
        z-index: 1000;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
    }

    .logo {
        display: none;
    }

    .logotel {
        font-weight: 600;
        padding: 5px;
        padding-right: 15px;
        font-size: 16px;
        font-style: italic;
        letter-spacing: -0.24em;
        color: #ffa800;
    }

    a {
        text-decoration: none;
        color: white;

    }


    .topnav__liste {
        margin: 0;
        padding: 0;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        transition: all .6s cubic-bezier(1, .19, .02, .99);
        transform: translateY(0);
        display: inline-block;
        list-style: none;
        background-color: #171717;

    }

    .topnav__el {
        font-family: "Plus Jakarta Sans", sans-serif;
        list-style: none;
        font-size: 18px;
        line-height: 29px;
        font-weight: 100;
        font-style: normal;
        padding: 60px 0px;
        text-align: center;
        margin-right: 15%;
        transform: translateY(0%);
        opacity: 0;
        transition: all .6s cubic-bezier(1, .19, .02, .99);
    }

    .topnav--open .topnav__liste {
        transform: translateX(-90%);
    }

    .topnav--open .topnav__el {
        transform: translateY(0);
        opacity: 1;
    }

    .topnav--open .topnav__el:nth-child(1) {
        padding-top: 150px;
        transition-delay: 0.5s;
    }

    .topnav--open .topnav__el:nth-child(2) {
        transition-delay: 0.7s;
    }

    .topnav--open .topnav__el:nth-child(3) {
        transition-delay: 0.9s;
    }
}


@media(min-width: 1280px) {

    nav {
        position: fixed;
        width: 100%;
        z-index: 10;
    }

    .logotel {
        display: none;
    }

    .topnav__el a.actifnav {
        border-bottom: 1px solid #ffa800;

    }

    .topnav__burger {
        display: none;
    }

    a {
        text-decoration: none;
        color: white;
    }

    li {
        list-style: none;
    }

    .topnav__liste,
    .nav2 {
        display: flex;
    }

    .topnav__liste {
        align-items: center;
        justify-content: space-between;
        padding: 46px 0px 0px;
        margin: 0px 35px 46px 30px;

    }

    .topnav__el {
        padding-left: 70px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2em;

        :hover {
            color: #ffa800;
        }
    }

    .logo {
        font-weight: 600;
        padding: 5px;
        padding-right: 15px;
        font-size: 16px;
        font-style: italic;
        letter-spacing: -0.24em;
        color: #ffa800;
        transition: .2s cubic-bezier(0, 0, .02, .99);

        &--off {
            display: none;
        }

        &--modifier {
            letter-spacing: 0.2em;
        }

    }


}


@media(min-width:1440px) {

    .topnav__liste {
        margin: 0px 46px 46px 41px;

    }

}



@media(min-width:1600px) {
    .topnav__liste {
        margin: 0px 56px 46px 51px;
    }
}

@media(min-width:1760px) {
    .topnav__liste {
        margin: 0px 56px 46px 96px;
    }
}

@media(min-height:940px) {

    .topnav__liste {
        padding: 90px 0px 0px;
    }
}