.sectionprojets {
    height: 100vh;
}


@media(min-width:750px) {

    .pinactif {
        display: flex;
        justify-content: center;
        height: 100%;

    }

    .border1 {
        border-top: 1px solid #ffa800;
        width: 800px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .border2 {
        border-top: 1px solid #ffa800;
        width: 800px;
        position: absolute;
        top: 83%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

}