.sectionphotos {

    z-index: 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 140px;


    img {
        max-width: 100%;
    }


    .p1 {
        grid-column: 1/4;
        grid-row: 1/2;
    }

    .p2 {
        grid-column: 2/5;
        grid-row: 2/3;
    }

    .p3 {
        grid-column: 1/4;
        grid-row: 3/4;
    }

    .p4 {
        grid-column: 2/5;
        grid-row: 4/5;
    }

}

.reveal {
    position: relative;
    opacity: 0;
}


.reveal.active {
    opacity: 1;
}

.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


@media(min-width:750px) {
    .sectionphotos {

        z-index: 2;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-template-rows: repeat(4, 1fr);
        row-gap: 140px;


        img {
            max-width: 100%;
            filter: blur(10px);
            transition: filter 0.3s ease;


            &:hover {
                filter: blur(0px);
            }
        }


        .p1 {
            grid-column: 2/5;
            grid-row: 1/2;
        }

        .p2 {
            grid-column: 8/11;
            grid-row: 2/3;
        }

        .p3 {
            grid-column: 3/6;
            grid-row: 3/4;
        }

        .p4 {
            grid-column: 9/12;
            grid-row: 4/5;
        }

    }
}