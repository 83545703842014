.presentationprojet {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: #171717;
    z-index: 1000;
    overflow-x: hidden;
    display: none;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    .borderprojet {
        border-left: 1px solid #ffa800;
        margin: 0px 0px 20px 18px;
        padding: 10px 0px 10px 18px;
    }

    p {
        margin-top: 29px;
        margin-right: 18px;
    }
}

.flexpresentation {

    img {
        width: 300px;
        height: 190px;
        margin-top: 29px;
        filter: blur(0px);
    }
}

.linkprojet {
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #ffa800;
    font-size: 13px;
    margin: 29px 0px 10px;
    display: block;
}

.buttonprojet {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 19px 8px;
    padding: 10px;
    transition: transform 0.3s ease;
}

.hidden {
    display: none;
}

.slidebuttonprojet {
    position: absolute;
    right: 1%;
    top: 4%;
}

.prev-project,
.next-project {
    cursor: pointer;
    padding: 0px 18px;
    color: white;
    font-size: 22px;
    border: none;
    background-color: transparent;
}

.next-project {
    right: 0;
}


.slide-in-right {
    transform: translateX(100%);
    opacity: 0;
    animation: slide-in-right 0.5s forwards;
}

.slide-in-left {
    transform: translateX(-100%);
    opacity: 0;
    animation: slide-in-left 0.5s forwards;
}

.slide-out-left {
    transform: translateX(-100%);
    opacity: 1;
    animation: slide-out-left 0.5s forwards;
}

.slide-out-right {
    transform: translateX(100%);
    opacity: 1;
    animation: slide-out-right 0.5s forwards;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slide-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}


@media(min-width:750px) {

    .slide-in-right {
        transform: translate(150%, -42%);
        opacity: 0;
        animation: slide-in-right 0.5s forwards;
    }

    .slide-in-left {
        transform: translate(-150%, -42%);
        opacity: 0;
        animation: slide-in-left 0.5s forwards;
    }

    .slide-out-left {
        transform: translate(-150%, -42%);
        opacity: 1;
        animation: slide-out-left 0.5s forwards;
    }

    .slide-out-right {
        transform: translate(150%, -42%);
        opacity: 1;
        animation: slide-out-right 0.5s forwards;
    }

    /* Keyframes pour les animations */
    @keyframes slide-in-right {
        0% {
            transform: translate(150%, -42%);
            opacity: 0;
        }

        100% {
            transform: translate(-50%, -42%);
            opacity: 1;
        }
    }

    @keyframes slide-in-left {
        0% {
            transform: translate(-150%, -42%);
            opacity: 0;
        }

        100% {
            transform: translate(-50%, -42%);
            opacity: 1;
        }
    }

    @keyframes slide-out-left {
        0% {
            transform: translate(-50%, -42%);
            opacity: 1;
        }

        100% {
            transform: translate(-150%, -42%);
            opacity: 0;
        }
    }

    @keyframes slide-out-right {
        0% {
            transform: translate(-50%, -42%);
            opacity: 1;
        }

        100% {
            transform: translate(150%, -42%);
            opacity: 0;
        }
    }

    .presentationprojet {
        height: 80vh;
        width: 80vw;
        position: fixed;
        top: 50%;
        left: 50%;
        background-color: #171717;
        z-index: 1000;
        overflow: hidden;
        display: none;
        transform: translate(-50%, -42%);
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        border: 1px #ffa800 solid;

        .borderprojet {
            border-left: none;
        }

    }


    .flexpresentation {
        display: flex;

        p {
            max-width: 600px;
        }

        img {
            width: 300px;
            height: 190px;
            margin-left: 100px;
            margin-top: 35px;
            margin-right: 18px;

            filter: blur(0px);
        }
    }

    .linkprojet {
        text-transform: uppercase;
        letter-spacing: 5px;
        color: #ffa800;
        font-size: 13px;
        margin: 35px 0px 10px;
        display: block;
        transition: letter-spacing 0.1s ease;
        width: fit-content;

        &:hover {

            letter-spacing: 7px;
        }
    }

    .buttonprojet {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 18px 19px 18px;
        padding: 10px;
        transition: transform 0.3s ease;
    }

    .buttonprojet:hover {
        transform: rotate(90deg);
    }

    .hidden {
        display: none;
    }

    .slidebuttonprojet {
        position: absolute;
        top: unset;
        right: 1%;
        bottom: 2%;
    }

    .prev-project,
    .next-project {
        cursor: pointer;
        padding: 0px 18px;
        color: white;
        font-size: 22px;
        transition: 0.3s ease;
        border: none;
        background-color: transparent;
    }

    .next-project {
        right: 0;
    }

    .prev-project:hover,
    .next-project:hover {
        transform: scale(1.3);
        color: #ffa800;
    }


}

@media(min-height:750px) {
    .presentationprojet {
        height: 75vh;
    }
}

@media(min-height:800px) {
    .presentationprojet {
        height: 70vh;
    }
}

@media(min-height:900px) {
    .presentationprojet {
        height: 65vh;
    }
}



@media(min-width:1600px) {

    .flexpresentation {

        p {
            font-size: 20px;
            max-width: 650px;
        }

        img {
            margin-left: 150px;
            width: 390px;
            height: 247px;
        }
    }
}



@media(min-width:1920px) {
    .flexpresentation {

        p {
            font-size: 22px;
            max-width: 700px;
        }

        img {
            margin-left: 150px;
            width: 390px;
            height: 247px;
        }
    }

}

@media(min-width:750px) {

    @media(max-height:800px) {


        .flexpresentation {
            img {
                margin-top: 70px;
            }
        }
    }


    @media(min-height:940px) {
        .flexpresentation {
            margin-top: 35px;
        }
    }

}