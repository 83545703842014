header {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.backgroundlight {
    display: none;
}

.nomprenom {
    display: none;
}

.presentation {
    p {
        margin-bottom: 29px;
    }
}

.buttonarrow {
    display: none;
}

@media(min-width:750px) {
    header {
        min-height: 100vh;
        position: relative;
        overflow: hidden;
    }

    .titre {
        display: flex;
        flex-direction: column;

    }


    .presentation {
        max-width: 500px;
        margin-left: 35px;

        p {
            margin-bottom: 35px;
        }
    }




}

@media(min-width:1000px) {


    .section__header {
        display: flex;
        align-items: center;
        margin-top: 121px;
    }




}



@media(min-width:1280px) {
    header {
        height: 100vh;
        position: relative;
        overflow: hidden;
    }

    .backgroundlight {
        display: block;
        z-index: -1;
        position: absolute;
        width: 1196px;
        height: 1700px;
        left: 600px;
        top: -900px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.25) 0%, rgba(23, 23, 23, 0.25) 100%);
        transform: rotate(-31.87deg);

    }

    .titre {
        display: flex;
        flex-direction: column;

    }

    .section__header {
        display: flex;
        align-items: center;
        margin-top: 121px;
    }

    .presentation {
        max-width: 430px;
        margin-left: 35px;

        p {
            margin-bottom: 35px;
        }
    }

    .nomprenom {
        display: block;
        visibility: hidden;
        position: absolute;
        -webkit-text-stroke: 1px #93835e;
        color: transparent;
        bottom: 0%;
        left: 90%;

        font-family: "muli", sans-serif;
        font-weight: 900;
        font-style: normal;
        font-size: 115px;
        line-height: 195px;
        opacity: 0.5;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(180deg);

    }

    .buttonarrow {
        display: flex;
        justify-content: center;

        svg {
            position: absolute;
            bottom: 7%;

            &:hover {
                transform: translateY(2px);
            }
        }


    }


}



@media(min-width:1440px) {

    .nomprenom {
        left: 91%;

    }


    .backgroundlight {
        left: 760px;
    }

}


@media(min-width:1600px) {

    .nomprenom {
        left: 92%;

    }

    .presentation {
        max-width: 500px;
        margin-left: 70px;

        p {
            font-size: 20px;
        }

    }

    .backgroundlight {
        left: 920px;
    }

}


@media(min-width:1760px) {

    .nomprenom {
        left: 93%;

    }

    .presentation {
        margin-left: 100px;
    }

    .backgroundlight {
        left: 1080px;
    }

}


@media(min-width:1920px) {



    .presentation {
        max-width: 550px;

        p {
            font-size: 22px;
        }

    }

    .backgroundlight {
        left: 1240px;
    }

}

@media(min-width:750px) {

    @media(max-height:800px) {

        .nomprenom {
            font-size: 100px;

        }


    }

    @media(min-height:870px) {

        .nomprenom {
            font-size: 125px;

        }


        .section__header {
            margin-top: 156px;
        }


    }

    @media(min-height:940px) {

        .nomprenom {
            font-size: 135px;

        }

        .section__header {
            margin-top: 190px;
        }

    }


    @media(min-height:1010px) {

        .nomprenom {
            font-size: 145px;

        }

    }

    @media(min-height:1080px) {

        .nomprenom {
            font-size: 155px;

        }


        .section__header {
            margin-top: 220px;
        }

    }

}