.sectionapropos {

    margin-top: 150vh;
    padding-top: 100px;

    p {
        margin-bottom: 29px;
    }

    a {
        display: block;
        border: 1px solid white;
        padding: 10px;
        width: 100px;
        margin-bottom: 29px;
        text-align: center;
    }


}



@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.sectionapropos p {
    opacity: 0;
    /* Les paragraphes sont invisibles par défaut */
    transition: opacity 0.5s ease;
    /* Transition pour rendre le paragraphe visible */
}

.sectionapropos p span {
    display: inline-block;
    /* Chaque mot est un bloc inline pour l'animation */
    opacity: 0;
    /* Les mots sont invisibles par défaut */
    animation: slideUp 0.5s forwards;
    /* Animation des mots */
    margin-right: 4px;
    /* Espace entre les mots */
}

@media(min-width:750px) {



    .sectionapropos {
        padding-top: 0;
        margin-top: 200vh;

        p {
            margin: 35px;
            max-width: 600px;
        }

        a {
            display: block;
            border: 1px solid white;
            padding: 10px;
            margin: 70px 35px;
            width: 100px;
            text-align: center;

            &:hover {
                border-color: #ffa800;
                color: #ffa800;
            }
        }
    }







}

@media(min-width:1440px) {
    .sectionapropos {

        p,
        a {
            margin-left: 46px;
        }


    }
}

@media(min-width:1600px) {
    .sectionapropos {

        p,
        a {
            margin-left: 56px;
            font-size: 20px;
            max-width: 655px;
        }
    }
}

@media(min-width:1760px) {
    .sectionapropos {

        p,
        a {
            margin-left: 96px;
        }
    }
}

@media(min-width:1920px) {
    .sectionapropos {

        p {
            font-size: 22px;
            max-width: 700px;
        }
    }
}


@media(min-width:750px) {
    @media(min-height:870px) {
        .sectionapropos {

            a {
                margin-bottom: 105px;

            }
        }
    }

    @media(min-height:1010px) {
        .sectionapropos {

            a {
                margin-bottom: 140px;

            }
        }
    }


    @media(min-height:1080px) {
        .sectionapropos {

            a {
                margin-top: 105px;

            }
        }
    }
}