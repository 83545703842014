@import '../../node_modules/modern-normalize/modern-normalize';
@import 'utils/variables';
@import 'components/type';
@import 'components/nav';
@import 'components/header';
@import 'components/sectionprojet';
@import 'components/sectionphoto';
@import 'components/sectionapropos';
@import 'components/presentationprojets';
@import 'components/taquinjeu';
@import 'components/footer';

html {
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 5px;
        transition: width 0.3s ease;
        /* Transition douce */
        /* Largeur de la scrollbar */

    }



    ::-webkit-scrollbar-track {
        background: black;
        /* Couleur du fond de la scrollbar */

    }

    ::-webkit-scrollbar-thumb {
        background-color: #ffa800;
        /* Couleur du "pouce" de la scrollbar */
        border-radius: 1px;
        /* Arrondir les coins */
        border: 1px solid #ffa800;
        /* Espacement entre le pouce et le track */

    }
}


body {

    overflow-x: hidden;
    margin: 0;
    margin-left: 18px;
    margin-right: 18px;
    padding: 0;
    background-color: #171717;
    font-size: 18px;
    color: white;
    line-height: 29px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;



}

h1 {
    font-size: 118px;
    line-height: 118px;
    margin: 0;
    margin-top: 116px;
    margin-bottom: 58px;
    border-left: 1px solid #ffa800;
    padding: 0px 0px 10px 18px;

}

h2 {
    font-weight: 550;
    font-style: italic;
    font-size: 73.75px;
    line-height: 74px;
    padding-top: 100px;
    margin: 0;
}

h3 {
    font-size: 46px;
    font-weight: 600;
    margin: 0;
    line-height: 46px;
    margin-bottom: 0px;

    span {
        color: #ffa800;
    }
}

.noscroll {
    overflow: hidden;
}


p {
    padding: 0;
    margin: 0;
}

.textbold {
    font-weight: 800;
}

@media(min-width:750px) {


    body {
        font-size: 18px;
        line-height: 29px;
        margin: 0;
    }

    h1 {
        font-weight: 600;
        font-size: 180px;
        line-height: 180px;
        border-left: 1px solid #ffa800;
        margin: 20px 0px 25px 35px;

        padding: 50px;


    }

    h2 {
        font-weight: 550;
        font-style: italic;
        font-size: 125px;
        display: flex;
        align-items: center;
    }

    h3 {
        font-size: 100px;
        font-weight: 600;
        margin: 0;
        line-height: 170px;
        margin-bottom: 60px;

        span {
            color: #ffa800;
        }
    }

}

@media(min-width:1280px) {


    body {
        font-size: 18px;
        line-height: 35px;
        margin: 0;
    }

    h1 {
        font-weight: 600;
        font-size: 220px;
        line-height: 200px;
        border-left: 1px solid #ffa800;
        margin: 20px 0px 25px 35px;

        padding: 50px;


    }

    h2 {
        font-weight: 550;
        font-style: italic;
        font-size: 220px;
        display: flex;
        align-items: center;
    }

    h3 {
        font-size: 120px;
        font-weight: 600;
        margin: 0;
        line-height: 100px;

        span {
            color: #ffa800;
        }
    }

    @media(max-height:800px) {
        h3 {
            margin-bottom: 0;
        }
    }
}

@media(min-width:1440px) {
    h1 {
        margin: 20px 0px 25px 45px;
    }

    h3 {
        line-height: 130px;
    }
}



@media(min-width:1600px) {

    h1 {
        margin: 20px 0px 25px 55px;
        font-size: 255px;
        line-height: 235px;
    }

    h3 {
        font-size: 150px;
    }

}

@media(min-width:1760px) {

    h1 {
        margin: 20px 0px 25px 100px;
        padding-left: 100px;
    }
}

@media(min-width:1920px) {

    h1 {
        font-size: 290px;
        line-height: 270px;
    }

    h3 {
        font-size: 210px;
        line-height: 190px;
    }
}