.bodytaquin {
    height: 100vh;
    overflow-x: hidden;
}

.logo2 {
    font-weight: 600;
    position: absolute;
    top: 2%;
    font-size: 16px;
    font-style: italic;
    letter-spacing: -0.24em;
    color: #ffa800;
    transition: .2s cubic-bezier(0, 0, .02, .99);

    &--off1 {
        display: none;
    }

    &--modifier1 {
        letter-spacing: 0.2em;
    }

}

select {
    border: solid 1px white;
    border-radius: 5px;
    color: white;
    background-color: #171717;

}

.taquinmp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
    flex-direction: column;

    img {
        width: 324px;
        height: 324px;
    }
}

#projetSelect {
    margin-top: 18px;
}

.taquinjeu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-direction: column;
}

#taquin {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 1px;
    width: 90vw;
    max-width: 400px;
    height: auto;
    margin: 18px;
}

.piece {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: 400% 400%;
    cursor: pointer;
    border: 1px solid #171717;
}

#chrono {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

#completionMessage {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.hidden {
    display: none;
}


@media(min-width:750px) {

    .logo2 {
        left: 3%;
        top: 2%;
    }
}

@media(min-width:1280px) {


    .bodytaquin {
        height: 100vh;
        overflow: hidden;
    }

    .taquinmp {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 80vh;
        flex-direction: row;

        img {
            width: 403px;
            height: 403px;
        }
    }

    #projetSelect {
        margin-top: -35px;
    }

    .taquinjeu {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        flex-direction: column;
    }

    #taquin {
        display: grid;
        grid-template-columns: repeat(4, 100px);
        grid-template-rows: repeat(4, 100px);
        gap: 1px;
        margin: 35px;
    }

    .piece {
        width: 100px;
        height: 100px;
        background-size: 400px 400px;
        cursor: pointer;
        border: 1px solid #171717;
    }

    #chrono {
        margin-top: 20px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 105px;
    }

    #completionMessage {
        margin-top: 20px;
        font-size: 24px;
        font-weight: bold;
        color: white;
    }

    .hidden {
        display: none;
    }
}