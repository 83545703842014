.footer {
    border-top: solid 1px #ffa800;

    &__liste {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        list-style: none;
    }
}

@media(min-width:750px) {

    .footer {

        &__liste {
            flex-direction: row;
            padding: 0px 50px;
            margin: 75px 0px;
        }

        a {
            &:hover {
                color: #ffa800;
            }
        }
    }
}

@media(min-width:1440px) {
    .footer__liste {
        padding: 0px 46px;
    }
}

@media(min-width:1600px) {
    .footer__liste {
        padding: 0px 56px;
    }
}

@media(min-width:1760px) {
    .footer__liste {
        padding: 0px 96px;
    }
}